<template>
  <div class="pb-4">
    <b-row class="mb-3">
      <b-col class="col-12 px-0 text-right">
        <span class="mr-3">
          <label for="month_selector" class="mr-1 mb-0">Mese</label>

          <select
            class="form-select bg-white border border-info general_border py-1"
            @change="onMonthYearChange"
            v-model="selectedMonth"
            size="sm"
          >
            <option
              v-for="month in monthMapping"
              :key="month.id"
              :value="month.id"
              id="month_selector"
              class="text-secondary"
            >
              {{ month.name }}
            </option>
          </select>
        </span>
        <span class="mr-3">
          <label for="year_selector" class="mr-1 mb-0">Anno</label>

          <select
            class="form-select bg-white border border-info general_border py-1"
            v-model="selectedYear"
            id="year_selector"
            @change="onMonthYearChange"
            size="sm"
          >
            <option
              v-for="year in years"
              :key="year"
              :value="year"
              class="text-secondary"
            >
              {{ year }}
            </option>
          </select>
        </span>
      </b-col>
    </b-row>
    <div class="d-flex justify-content-between mb-3">
      <b-button
        :size="$root.isMobile ? 'sm' : ''"
        variant="outline-secondary border-white"
        type="button"
        @click="setPrev"
      >
        <b-icon
          font-scale="1.5"
          class="mx-2"
          icon="arrow-left-square-fill"
        ></b-icon
        >{{ !$root.isMobile ? "Precedente" : "" }}
      </b-button>
      <h5 class="text-capitalize d-flex align-items-center mb-0">
        {{ start | moment("D MMM") }} - {{ end | moment("D MMM") }}
      </h5>
      <b-button
        :size="$root.isMobile ? 'sm' : ''"
        variant="outline-secondary border-white"
        type="button"
        @click="setPost"
        >{{ !$root.isMobile ? "Successiva" : "" }}
        <b-icon
          font-scale="1.5"
          class="mx-2"
          icon="arrow-right-square-fill"
        ></b-icon>
      </b-button>
    </div>
    <div>
      <b-table
        :fixed="!$root.isMobile"
        class="no-bold-th shadow_2 general_border_11"
        small
        borderless
        responsive
        table-variant="white"
        :fields="fields"
        :items="prenotazioni"
        sticky-header
      >
        <!-- <template #cell(time)="row" class="bg-info">
          <div class="d-flex h-100 align-content-end">
            <strong class="text-medium-grey mb-0">{{ row.item.time }}</strong>
          </div>
        </template> -->

        <template #head(day0)="data">
          <p
            :title="'mostra solo lunedì ' + data.label"
            class="light__effect px-3 w-100 mb-0"
          >
            <span>lun</span>
            <br />
            <span class="mb-0 bigger__text">
              {{ formatHeadLabel(data.label) }}
            </span>
          </p>
        </template>
        <template #head(day1)="data">
          <p
            :title="'mostra solo martedì ' + data.label"
            class="light__effect px-3 w-100 mb-0"
          >
            <span>mar</span>
            <br />
            <span class="mb-0 bigger__text">
              {{ formatHeadLabel(data.label) }}
            </span>
          </p>
        </template>
        <template #head(day2)="data">
          <p
            :title="'mostra solo mercoledì ' + data.label"
            class="light__effect px-3 w-100 mb-0"
          >
            <span>mer</span>
            <br />
            <span class="mb-0 bigger__text">
              {{ formatHeadLabel(data.label) }}
            </span>
          </p>
        </template>
        <template #head(day3)="data">
          <p
            :title="'mostra solo giovedì ' + data.label"
            class="light__effect px-3 w-100 mb-0"
          >
            <span>gio</span>
            <br />
            <span class="mb-0 bigger__text">
              {{ formatHeadLabel(data.label) }}
            </span>
          </p>
        </template>
        <template #head(day4)="data">
          <p
            :title="'mostra solo venerdì ' + data.label"
            class="light__effect px-3 w-100 mb-0"
          >
            <span>ven</span>
            <br />
            <span class="mb-0 bigger__text">
              {{ formatHeadLabel(data.label) }}
            </span>
          </p>
        </template>
        <template #head(day5)="data">
          <p
            :title="'mostra solo sabato ' + data.label"
            class="light__effect px-3 w-100 mb-0"
          >
            <span>sab</span>
            <br />
            <span class="mb-0 bigger__text">
              {{ formatHeadLabel(data.label) }}
            </span>
          </p>
        </template>
        <template #head(day6)="data">
          <p
            :title="'mostra solo domenica ' + data.label"
            class="light__effect px-3 w-100 mb-0"
          >
            <span>dom</span>
            <br />
            <span class="mb-0 bigger__text">
              {{ formatHeadLabel(data.label) }}
            </span>
          </p>
        </template>
        <template #cell(day0)="row">
          <div v-for="(app, ind) in row.item.day0" :key="'day0' + ind">
            <div
              v-for="elem in app"
              :key="elem.hashid"
              @click="showReservDetail(elem)"
              style="position: relative"
              class="thicker__border general_border general_border py-2 my-1 mx-auto point"
              :class="
                elem.status > 0
                  ? `${'bg-' + colorStatusPositive[elem.status]}`
                  : 'bg-rifiutato-annullato'
              "
            >
              <p
                class="d-flex align-items-center justify-content-around mb-0 px-1"
              >
                <span class="">
                  <b-iconstack font-scale="2.05">
                    <b-icon stacked icon="circle-fill" variant="white"></b-icon>
                    <b-icon
                      stacked
                      :icon="elem.icona > 1 ? icons[2] : icons[elem.icona]"
                      scale="0.55"
                    ></b-icon>
                  </b-iconstack>
                </span>
                <span class="text-uppercase text-white">
                  {{ elem.data_inizio | moment("HH:mm") }}
                </span>
                <b-badge
                  v-if="elem.unreadMessages"
                  class="fs_badge ml-1"
                  variant="danger"
                  >{{ elem.unreadMessages }}</b-badge
                >
              </p>
            </div>
          </div>
        </template>
        <template #cell(day1)="row">
          <div v-for="(app, ind) in row.item.day1" :key="'day1' + ind">
            <div
              v-for="elem in app"
              :key="elem.hashid"
              @click="showReservDetail(elem)"
              style="position: relative"
              class="thicker__border general_border py-2 my-1 mx-auto point"
              :class="
                elem.status > 0
                  ? `${'bg-' + colorStatusPositive[elem.status]}`
                  : 'bg-rifiutato-annullato'
              "
            >
              <p
                class="d-flex align-items-center justify-content-around mb-0 px-1"
              >
                <span class="">
                  <b-iconstack font-scale="2.05">
                    <b-icon stacked icon="circle-fill" variant="white"></b-icon>
                    <b-icon
                      stacked
                      :icon="elem.icona > 1 ? icons[2] : icons[elem.icona]"
                      scale="0.55"
                    ></b-icon>
                  </b-iconstack>
                </span>
                <span class="text-uppercase text-white">
                  {{ elem.data_inizio | moment("HH:mm") }}
                </span>
                <b-badge
                  v-if="elem.unreadMessages"
                  class="fs_badge ml-1"
                  variant="danger"
                  >{{ elem.unreadMessages }}</b-badge
                >
              </p>
            </div>
          </div>
        </template>

        <template #cell(day2)="row">
          <div v-for="(app, ind) in row.item.day2" :key="'day2' + ind">
            <div
              v-for="elem in app"
              :key="elem.hashid"
              @click="showReservDetail(elem)"
              style="position: relative"
              class="thicker__border general_border py-2 my-1 mx-auto point"
              :class="
                elem.status > 0
                  ? `${'bg-' + colorStatusPositive[elem.status]}`
                  : 'bg-rifiutato-annullato'
              "
            >
              <p
                class="d-flex align-items-center justify-content-around mb-0 px-1"
              >
                <span class="">
                  <b-iconstack font-scale="2.05">
                    <b-icon stacked icon="circle-fill" variant="white"></b-icon>
                    <b-icon
                      stacked
                      :icon="elem.icona > 1 ? icons[2] : icons[elem.icona]"
                      scale="0.55"
                    ></b-icon>
                  </b-iconstack>
                </span>
                <span class="text-uppercase text-white">
                  {{ elem.data_inizio | moment("HH:mm") }}
                </span>
                <b-badge
                  v-if="elem.unreadMessages"
                  class="fs_badge ml-1"
                  variant="danger"
                  >{{ elem.unreadMessages }}</b-badge
                >
              </p>
            </div>
          </div>
        </template>
        <template #cell(day3)="row">
          <div v-for="(app, ind) in row.item.day3" :key="'day3' + ind">
            <div
              v-for="elem in app"
              :key="elem.hashid"
              @click="showReservDetail(elem)"
              style="position: relative"
              class="thicker__border general_border py-2 my-1 mx-auto point"
              :class="
                elem.status > 0
                  ? `${'bg-' + colorStatusPositive[elem.status]}`
                  : 'bg-rifiutato-annullato'
              "
            >
              <p
                class="d-flex align-items-center justify-content-around mb-0 px-1"
              >
                <span class="">
                  <b-iconstack font-scale="2.05">
                    <b-icon stacked icon="circle-fill" variant="white"></b-icon>
                    <b-icon
                      stacked
                      :icon="elem.icona > 1 ? icons[2] : icons[elem.icona]"
                      scale="0.55"
                    ></b-icon>
                  </b-iconstack>
                </span>
                <span class="text-uppercase text-white">
                  {{ elem.data_inizio | moment("HH:mm") }}
                </span>
                <b-badge
                  v-if="elem.unreadMessages"
                  class="fs_badge ml-1"
                  variant="danger"
                  >{{ elem.unreadMessages }}</b-badge
                >
              </p>
            </div>
          </div>
        </template>
        <template #cell(day4)="row">
          <div v-for="(app, ind) in row.item.day4" :key="'day4' + ind">
            <div
              v-for="elem in app"
              :key="elem.hashid"
              @click="showReservDetail(elem)"
              style="position: relative"
              class="thicker__border general_border py-2 my-1 mx-auto point"
              :class="
                elem.status > 0
                  ? `${'bg-' + colorStatusPositive[elem.status]}`
                  : 'bg-rifiutato-annullato'
              "
            >
              <p
                class="d-flex align-items-center justify-content-around mb-0 px-1"
              >
                <span class="">
                  <b-iconstack font-scale="2.05">
                    <b-icon stacked icon="circle-fill" variant="white"></b-icon>
                    <b-icon
                      stacked
                      :icon="elem.icona > 1 ? icons[2] : icons[elem.icona]"
                      scale="0.55"
                    ></b-icon>
                  </b-iconstack>
                </span>
                <span class="text-uppercase text-white">
                  {{ elem.data_inizio | moment("HH:mm") }}
                </span>
                <b-badge
                  v-if="elem.unreadMessages"
                  class="fs_badge ml-1"
                  variant="danger"
                  >{{ elem.unreadMessages }}</b-badge
                >
              </p>
            </div>
          </div>
        </template>
        <template #cell(day5)="row">
          <div v-for="(app, ind) in row.item.day5" :key="'day5' + ind">
            <div
              v-for="elem in app"
              :key="elem.hashid"
              @click="showReservDetail(elem)"
              style="position: relative"
              class="thicker__border general_border py-2 my-1 mx-auto point"
              :class="
                elem.status > 0
                  ? `${'bg-' + colorStatusPositive[elem.status]}`
                  : 'bg-rifiutato-annullato'
              "
            >
              <p
                class="d-flex align-items-center justify-content-around mb-0 px-1"
              >
                <span class="">
                  <b-iconstack font-scale="2.05">
                    <b-icon stacked icon="circle-fill" variant="white"></b-icon>
                    <b-icon
                      stacked
                      :icon="elem.icona > 1 ? icons[2] : icons[elem.icona]"
                      scale="0.55"
                    ></b-icon>
                  </b-iconstack>
                </span>
                <span class="text-uppercase text-white">
                  {{ elem.data_inizio | moment("HH:mm") }}
                </span>
                <b-badge
                  v-if="elem.unreadMessages"
                  class="fs_badge ml-1"
                  variant="danger"
                  >{{ elem.unreadMessages }}</b-badge
                >
              </p>
            </div>
          </div>
        </template>
        <template #cell(day6)="row">
          <div v-for="(app, ind) in row.item.day6" :key="'day6' + ind">
            <div
              v-for="elem in app"
              :key="elem.hashid"
              @click="showReservDetail(elem)"
              style="position: relative"
              class="thicker__border general_border py-2 my-1 mx-auto point"
              :class="
                elem.status > 0
                  ? `${'bg-' + colorStatusPositive[elem.status]}`
                  : 'bg-rifiutato-annullato'
              "
            >
              <p
                class="d-flex align-items-center justify-content-around mb-0 px-1"
              >
                <span class="">
                  <b-iconstack font-scale="2.05">
                    <b-icon stacked icon="circle-fill" variant="white"></b-icon>
                    <b-icon
                      stacked
                      :icon="elem.icona > 1 ? icons[2] : icons[elem.icona]"
                      scale="0.55"
                    ></b-icon>
                  </b-iconstack>
                </span>
                <span class="text-uppercase text-white">
                  {{ elem.data_inizio | moment("HH:mm") }}
                </span>
                <b-badge
                  v-if="elem.unreadMessages"
                  class="fs_badge ml-1"
                  variant="danger"
                  >{{ elem.unreadMessages }}</b-badge
                >
              </p>
            </div>
          </div>
        </template>
      </b-table>
      <p class="mb-0" v-if="prenotazioni !== null && prenotazioni.length == 0">
        Nessuna richiesta qui.
      </p>
      <b-modal
        size="xl"
        centered
        id="modal-dettagliorec"
        ref="modal-dettagliorec"
        hide-footer
        hide-header
        scrollable
        body-class="p-0"
        @hidden="selectedReservation = null"
      >
        <DettaglioPrenotazioneCittadino
          :reservation="selectedReservation"
          @handle-status-update="handleStatusUpdate($event)"
          @handle-notes-update="handleNotesUpdate($event)"
          @fetch-data="fetchData()"
          @handle-update-request="handleUpdateRequest()"
        />
      </b-modal>
    </div>
    <b-row
      class="d-flex justify-content-start align-items-center flex-wrap mx-0 mt-n3 px-0"
      align-v="stretch"
      id="calendar-legend"
    >
      <b-col class="d-flex mr-3 mt-3">
        <span
          v-for="col in colorLegend"
          :key="col.label"
          class="d-flex align-items-center"
        >
          <b-icon
            :class="col.color"
            icon="circle-fill"
            font-scale="1.5"
          ></b-icon>
          <span class="mx-2">{{ col.label }}</span>
        </span>
      </b-col>
      <!-- <b-col class="d-flex mt-3">
        <span v-for="servizio in serviziLegend" :key="servizio.label">
          <img
            :src="require(`@/assets/dashboard-operatore/${servizio.icon}.svg`)"
            height="25"
            width="25"
            class="icon"
          />
          <span class="mx-2">{{ servizio.label }}</span>
        </span>
      </b-col> -->
      <b-col class="mx-0 mt-3 d-flex">
        <p class="px-2 py-1 mr-2 my-auto d-flex align-items-center">
          <b-icon :icon="icons[1]" font-scale="1.2"></b-icon>
          <span class="ml-2" style="font-weight: 300">Teleconsulto</span>
        </p>
        <p class="px-2 py-1 mr-2 my-auto d-flex align-items-center">
          <b-icon :icon="icons[0]" font-scale="1.2"></b-icon>
          <span class="ml-2">Ordine</span>
        </p>
        <p class="px-2 py-1 mr-2 my-auto d-flex align-items-center">
          <b-icon :icon="icons[2]" font-scale="1.2"></b-icon>
          <span class="mx-2">Prestazione sanitaria</span>
        </p>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import * as moment from "moment/moment"
import { mapActions } from "vuex"
import { reservationService } from "@/_services"
import { SERVIZI_VARIANTS } from "@/_utils/constants.js"
import DettaglioPrenotazioneCittadino from "@/components/prenotazioni/DettaglioPrenotazioneCittadino.vue"

export default {
  name: "CalendarioCittadino",
  props: ["servicerif"],
  components: {
    DettaglioPrenotazioneCittadino
  },
  data() {
    return {
      fields: [],
      SERVIZI_VARIANTS: SERVIZI_VARIANTS,
      prenotazioni: null,
      start: null,
      end: null,
      month: null,
      today: null,
      filters: {
        from: null,
        to: null,
        position_ref: this.servicerif,
        unread: true
      },
      icons: {
        0: "bag",
        1: "camera-video",
        2: "file-ppt",
        3: "chat-text",
        4: "screwdriver",
        5: "droplet-fill",
        6: "flower1",
        7: "emoji-smile",
        8: "exclude",
        9: "egg-fried",
        10: "gem",
        11: "heart-fill",
        12: "hourglass-split",
        13: "journal-medical",
        14: "life-preserver",
        15: "lock-fill",
        16: "moon",
        17: "node-minus-fill",
        18: "x-diamond-fill",
        19: "cart-fill",
        20: "bug-fill",
        21: "brightness-high-fill",
        22: "bell-fill"
      },
      statusModalIcons: {
        confermato: "check-circle",
        ricevuto: "archive",
        annullato: "x-circle",
        rifiutato: "x-circle",
        "in attesa di risposta": "question-circle",
        evaso: "calendar2-check"
      },
      statusModalVariants: {
        confermato: "secondary",
        ricevuto: "primary",
        annullato: "danger",
        rifiutato: "danger",
        "in attesa di risposta": "warning",
        evaso: "primary"
      },
      selectedDay: null,
      selectedIndex: null,
      statusPositive: {
        1: "secondary",
        2: "info",
        3: "info",
        4: "primary",
        5: "ter-4",
        6: "ter-4"
      },
      statusNegative: {
        1: "danger",
        2: "ter-2",
        3: "ter-2",
        4: "dark"
      },
      selectedReservation: null,
      colorStatusPositive: {
        1: "inoltrato-in-attesa",
        2: "confermato",
        3: "confermato",
        4: "evaso",
        5: "inoltrato-in-attesa",
        6: "inoltrato-in-attesa"
      },
      colorLegend: [
        {
          label: "Confermato",
          color: "confermato"
        },
        {
          label: "Evaso/Concluso",
          color: "evaso"
        },
        {
          label: "Inoltrato/In attesa di risposta",
          color: "inoltrato-in-attesa"
        },
        {
          label: "Errore/Rifiutato/Annullato",
          color: "rifiutato-annullato"
        }
      ],
      monthMapping: [
        { id: 0, name: "Gennaio" },
        { id: 1, name: "Febbraio" },
        { id: 2, name: "Marzo" },
        { id: 3, name: "Aprile" },
        { id: 4, name: "Maggio" },
        { id: 5, name: "Giugno" },
        { id: 6, name: "Luglio" },
        { id: 7, name: "Agosto" },
        { id: 8, name: "Settembre" },
        { id: 9, name: "Ottobre" },
        { id: 10, name: "Novembre" },
        { id: 11, name: "Dicembre" }
      ],
      years: [],
      selectedMonth: new Date().getMonth(),
      selectedYear: new Date().getFullYear()
    }
  },

  watch: {
    "filters.to": function (newValue, oldValue) {
      if (!oldValue) return
      const newDate = this.parseDateString(newValue)

      this.selectedMonth = newDate.getMonth()

      this.selectedYear = newDate.getFullYear()
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    setupCalendar() {
      var fields = []
      let newField = {
        key: "time",
        label: "",
        variant: "",
        tdClass: "text-medium-grey mb-0"
      }
      fields.push(newField)
      for (let x = 0; x <= 6; x++) {
        let startDay = moment(this.start)
        let todayDay = moment(this.today).format("DD/M")
        let date = startDay.add(x, "days").format("DD/M")
        let variant = undefined
        if (todayDay === date) {
          variant = "sec-4"
        }
        let newField = {
          key: "day" + parseInt(x),
          label: date,
          variant: variant
        }
        fields.push(newField)
      }
      this.fields = fields
      this.fetchData()
    },
    fetchData() {
      var self = this
      reservationService
        .fetchUserCalendar(this.filters)
        .then(function (response) {
          // console.log("response fetch prenotazioni", response.data.data);
          self.prenotazioni = response.data.data
        })
        .catch(function (error) {
          self.errorAlert("non è stato possibile recuperare le prenotazioni")
          // console.log("error fetch prenotazioni", error);
        })
    },
    cardVariant(obj) {
      let variant = ""
      if (obj.tipo_ordine) {
        variant = "bg-" + this.SERVIZI_VARIANTS[obj.stato] + "-light"
      }
      return variant
    },
    setPrev() {
      this.start.subtract(1, "weeks")
      this.end.subtract(1, "weeks")
      this.filters.from = moment(this.start).startOf("week").format("D/M/YY")
      this.filters.to = moment(this.end).endOf("week").format("D/M/YY")
      this.selectedDay = null
      this.selectedIndex = null
      this.setupCalendar()
    },
    setPost() {
      this.start.add(1, "weeks")
      this.end.add(1, "weeks")
      this.filters.from = moment(this.start).startOf("week").format("D/M/YY")
      this.filters.to = moment(this.end).endOf("week").format("D/M/YY")
      this.selectedDay = null
      this.selectedIndex = null
      this.setupCalendar()
    },
    setRef(ref, ind) {
      this.selectedDay = ref
      this.selectedIndex = ind
    },
    showReservDetail(res) {
      this.selectedReservation = res
      this.$bvModal.show("modal-dettagliorec")
    },
    handleStatusUpdate(data) {
      const item = this.selectedReservation
      var self = this
      if (!data.confirmed) {
        var form = data.message ? { message: data.message } : {}
        reservationService
          .userCancelOrReject(form, data.action, item.hashid)
          .then(function (re) {
            // self.$refs["modal-dettagliorec"].hide();
            window.scrollTo(0, 0)
            self.successAlert("Richiesta aggiornata con successo")
            self.fetchData()
          })
          .catch(function (error) {
            window.scrollTo(0, 0)
            self.errorAlert("non è stato possibile effettuare l'operazione")
          })
        this.$refs["modal-dettagliorec"].hide()
      } else {
        reservationService
          .userConfirmOrder(item.hashid)
          .then(function (re) {
            window.scrollTo(0, 0)
            self.successAlert("Richiesta aggiornata con successo")
            self.fetchData()
          })
          .catch(function (error) {
            self.loading = false
            window.scrollTo(0, 0)
            self.errorAlert("non è stato possibile effettuare l'operazione")
          })
        this.$refs["modal-dettagliorec"].hide()
      }
    },
    downloadPdf(res) {
      const hash = res.hashid
      const fName = res.codice_richiesta
      var self = this
      reservationService
        .scaricaPromemoria(hash)
        .then(function (response) {
          let blob = new Blob([response.data])
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download = fName + ".pdf"
          link.click()
        })
        .catch(function (error) {
          self.loading = false
          window.scrollTo(0, 0)
          self.errorAlert("non è stato possibile scaricare il promemoria")
        })
    },
    downloadCalendar(res) {
      const hash = res.hashid
      const fName = res.codice_richiesta
      var self = this
      reservationService
        .scaricaCalendar(hash)
        .then(function (response) {
          let blob = new Blob([response.data])
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download = fName + ".ics"
          link.click()
        })
        .catch(function (error) {
          self.loading = false
          window.scrollTo(0, 0)
          self.errorAlert("non è stato possibile scaricare il promemoria")
        })
    },
    handleNotesUpdate(data) {
      this.selectedReservation.user_notes = data.user_notes
    },
    handleUpdateRequest() {
      this.$bvModal.hide("modal-dettagliorec")
      this.successAlert("richiesta inviata con successo")
      this.fetchData()
    },
    formatHeadLabel(label) {
      try {
        return label.split("/")[0]
      } catch (err) {
        return label
      }
    },
    onMonthYearChange() {
      const firstDayOfMonth = new Date(this.selectedYear, this.selectedMonth, 1)
      this.start = moment(firstDayOfMonth).startOf("week")
      this.end = moment(firstDayOfMonth).endOf("week")
      this.filters.from = moment(this.start).startOf("week").format("D/M/YY")
      this.filters.to = moment(this.end).endOf("week").format("D/M/YY")
      this.setupCalendar()
    },
    parseDateString(dateString) {
      const parts = dateString.split("/")
      let year = parseInt(parts[2], 10)

      year = year < 100 ? 2000 + year : year

      return new Date(year, parts[1] - 1, parts[0])
    }
  },
  created() {
    this.start = moment().startOf("week")
    this.end = moment().endOf("week")
    this.today = moment()
    this.month = moment().month()
    this.filters.from = moment().startOf("week").format("D/M/YY")
    this.filters.to = moment().endOf("week").format("D/M/YY")
    // this.filters.position_ref = this.servicerif;
    this.setupCalendar()

    const currentYear = moment(this.start).year()

    for (let i = 2022; i <= currentYear + 3; i++) {
      this.years.push(i)
    }
  }
}
</script>
<style lang="scss">
// tr {
//   border-bottom: 2px solid $line-grey !important;
//   // border-bottom: 2px solid $font-light-grey !important;
// }
// tr:last-of-type {
//   border-bottom: 0 solid $line-grey !important;
// }
// .thicker__border {
//   border-width: 3px !important;
// }
</style>
<style>
.b-table-sticky-header {
  min-height: 65vh;
  max-height: 65vh;
}

.thicker__border {
  border-width: 2px !important;
  box-shadow: 0px 0px 4px 1px #c3c3c3;
}

.rifiutato-annullato {
  color: rgba(253, 39, 75, 1) !important;
}

.bg-rifiutato-annullato {
  background-color: rgba(253, 39, 75, 1) !important;
}

.inoltrato-in-attesa {
  color: rgba(255, 151, 18, 1) !important;
  /* color: rgba(224, 242, 111, 1) !important; */
}

.bg-inoltrato-in-attesa {
  background-color: rgba(255, 151, 18, 1) !important;
  /* color: rgba(224, 242, 111, 1) !important; */
}

.confermato {
  color: rgba(32, 80, 114, 1) !important;
  /* color: rgba(205, 224, 201, 0.45) !important; */
}

.bg-confermato {
  background-color: rgba(32, 80, 114, 1) !important;
  /* color: rgba(205, 224, 201, 0.45) !important; */
}

.evaso {
  color: #01c0cb !important;
  /* color: rgba(73, 67, 255, 1) !important; */
}

.bg-evaso {
  background-color: #01c0cb !important;
}

.cal__card {
  width: 10vw;
  max-width: 144px;
}
</style>
