<template>
  <b-container fluid class="m-0 p-0 p-md-1 p-lg-4">
    <template v-if="innerWidth > 768">
      <h4 class="text-prim-grad-1 text-left mb-4">Calendario</h4>

      <CalendarioCittadino />
    </template>
    <CalendarioCittadinoMobile v-else />
  </b-container>
</template>
<script>
import CalendarioCittadino from "@/components/prenotazioni/calendario/CalendarioCittadino.vue"
import CalendarioCittadinoMobile from "@/components/prenotazioni/calendario/CalendarioCittadinoMobile.vue"
export default {
  name: "AgendaCittadino",
  components: {
    CalendarioCittadino,
    CalendarioCittadinoMobile
  },
  data() {
    return {
      activePanel: "recenti",
      innerWidth: window.innerWidth
    }
  },
  created() {
    window.addEventListener("resize", this.innerWidthChanged)
  },
  mounted() {
    var lib = document.createElement("script")
    lib.setAttribute("id", "ls_lib")
    lib.setAttribute("type", "text/javascript")
    lib.setAttribute(
      "src",
      "https://cdn.jsdelivr.net/npm/fm.liveswitch@latest/fm.liveswitch.js"
    )
    document.head.appendChild(lib)
  },
  beforeDestroy() {
    var lib = document.getElementById("ls_lib")
    document.head.removeChild(lib)
    window.removeEventListener("resize", this.innerWidthChanged)
  },
  methods: {
    innerWidthChanged() {
      this.innerWidth = window.innerWidth
    }
  }
}
</script>
