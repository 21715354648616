<template>
  <div class="pb-4">
    <div class="d-flex justify-content-center mt-3">
      <b-form-datepicker
        label-help="Utilizza i cursori per navigare tra le date"
        start-weekday="1"
        id="calendario-cittadino-mobile-datepicker"
        v-model="filters.date"
        @input="fetchData()"
        class="mb-2 col-7 col-lg-5"
        :date-format-options="dateFormat"
        locale="it"
      ></b-form-datepicker>
    </div>
    <p class="d-flex justify-content-center align-items-center py-4 mb-0 px-4">
      <b-icon
        @click="subtractDay()"
        font-scale="1.5"
        icon="arrow-left-circle"
        variant="primary"
      ></b-icon>
      <span class="px-5"> giorno </span>
      <b-icon
        @click="addDay()"
        font-scale="1.5"
        icon="arrow-right-circle"
        variant="primary"
      ></b-icon>
    </p>
    <b-spinner variant="primary" label="loading" v-if="loading"></b-spinner>
    <b-container
      v-else-if="prenotazioni !== null && prenotazioni.length > 0"
      class="puntofarmaCard p-2 mb-4"
      id="calendario-cittadino-mobile-container"
    >
      <b-row
        v-for="(fasciaOraria, index) in prenotazioni"
        :key="index"
        class="border border-extra-light general_border"
      >
        <b-col class="col-3 col-sm-3 px-0">
          {{ fasciaOraria["time"] }}
        </b-col>
        <b-col class="d-flex flex-column align-items-center px-0">
          <div
            v-for="key in Object.keys(fasciaOraria).filter(
              (key) => key !== 'time'
            )"
            :key="key"
            class="d-flex flex-column justify-content-center align-item-center w-100"
          >
            <b-row
              class="w-100 my-1 d-flex justify-content-center align-item-center"
              v-for="res in Object.values(fasciaOraria[key])"
              :key="res.hashid"
            >
              <b-row
                @click="showDetail(res)"
                class="general_border d-flex align-items-center px-3 col-9 col-sm-6"
                :class="
                  res.status > 0
                    ? `${'bg-' + colorStatusPositive[res.status]}`
                    : 'bg-rifiutato-annullato'
                "
              >
                <b-col class="col-2 px-0">
                  <b-icon
                    :icon="res.icona > 1 ? icons[2] : icons[res.icona]"
                    scale="1"
                    variant="white"
                  ></b-icon>
                </b-col>
                <b-col class="col-10 px-0">
                  <span class="text-white">{{
                    res.data_inizio | moment("HH:mm")
                  }}</span>

                  <b-badge
                    v-if="res.unreadMessages"
                    class="fs_badge ml-1"
                    variant="danger"
                    >{{ res.unreadMessages }}</b-badge
                  >
                </b-col>
              </b-row>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <p class="mb-0" v-else>Nessuna richiesta qui.</p>

    <b-modal
      size="xl"
      centered
      id="modal-dettagliorec"
      ref="modal-dettagliorec"
      hide-footer
      hide-header
      scrollable
      body-class="p-0"
      @hidden="selectedReservation = null"
    >
      <DettaglioPrenotazioneCittadino
        :reservation="selectedReservation"
        @handle-status-update="handleStatusUpdate($event)"
        @handle-notes-update="handleNotesUpdate($event)"
        @fetch-data="fetchData()"
        @handle-update-request="handleUpdateRequest()"
      />
    </b-modal>
    <!-- <p class="d-flex justify-content-around align-items-center py-4 mb-0 px-4">
      <b-icon
        @click="subtractDay()"
        font-scale="3"
        icon="arrow-left-circle"
        variant="primary"
      ></b-icon>
      <b-icon
        @click="addDay()"
        font-scale="3"
        icon="arrow-right-circle"
        variant="primary"
      ></b-icon>
    </p> -->
    <b-row class="mx-0 mt-2">
      <p v-for="col in colorLegend" :key="col.label">
        <b-icon :class="col.color" icon="circle-fill" font-scale="1.5"></b-icon>
        <span class="mx-2">{{ col.label }}</span>
      </p>
    </b-row>
    <b-row class="mx-0 mt-2">
      <p class="">
        <b-icon :icon="icons[1]" font-scale="1.2"></b-icon>
        <span class="mx-2">Teleconsulto</span>
      </p>
      <p class="">
        <b-icon :icon="icons[0]" font-scale="1.2"></b-icon>
        <span class="mx-2">Ordine</span>
      </p>
      <p class="">
        <b-icon :icon="icons[2]" font-scale="1.2"></b-icon>
        <span class="mx-2">Prestazione sanitaria</span>
      </p>
    </b-row>
  </div>
</template>
<script>
import * as moment from "moment/moment"
import { mapActions } from "vuex"
import { reservationService } from "@/_services"
import DettaglioPrenotazioneCittadino from "@/components/prenotazioni/DettaglioPrenotazioneCittadino.vue"

export default {
  name: "CalendarioCittadino",
  props: ["servicerif"],
  components: {
    DettaglioPrenotazioneCittadino
  },
  data() {
    return {
      daysAllVisible: true,
      hoursAllVisible: true,
      loading: false,
      fields: [],
      prenotazioni: null,
      prenotazioniBackend: null,
      start: null,
      end: null,
      month: null,
      today: null,
      filters: {
        date: null,
        position_ref: this.servicerif,
        unread: true
      },
      selectedDay: null,
      selectedIndex: null,
      statusPositive: {
        1: "secondary",
        2: "info",
        3: "info",
        4: "primary",
        5: "ter-4",
        6: "ter-4"
      },
      statusNegative: {
        1: "danger",
        2: "ter-2",
        3: "ter-2",
        4: "dark"
      },

      statusModalIcons: {
        confermato: "check-circle",
        ricevuto: "archive",
        annullato: "x-circle",
        rifiutato: "x-circle",
        "in attesa di risposta": "question-circle",
        evaso: "calendar2-check"
      },
      statusModalVariants: {
        confermato: "secondary",
        ricevuto: "primary",
        annullato: "danger",
        rifiutato: "danger",
        "in attesa di risposta": "warning",
        evaso: "primary"
      },
      icons: {
        0: "bag",
        1: "camera-video",
        2: "file-ppt",
        3: "chat-text",
        4: "screwdriver",
        5: "droplet-fill",
        6: "flower1",
        7: "emoji-smile",
        8: "exclude",
        9: "egg-fried",
        10: "gem",
        11: "heart-fill",
        12: "hourglass-split",
        13: "journal-medical",
        14: "life-preserver",
        15: "lock-fill",
        16: "moon",
        17: "node-minus-fill",
        18: "x-diamond-fill",
        19: "cart-fill",
        20: "bug-fill",
        21: "brightness-high-fill",
        22: "bell-fill"
      },
      paddingMarginIcona: {
        0: "padding-margin-ordine",
        1: "padding-margin-teleconsulto",
        2: "padding-margin-prestazione"
      },
      selectedReservation: null,
      colorStatusPositive: {
        1: "inoltrato-in-attesa",
        2: "confermato",
        3: "confermato",
        4: "evaso",
        5: "inoltrato-in-attesa",
        6: "inoltrato-in-attesa"
      },
      fasceOrarie: [
        "0-7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22-0"
      ],
      colorLegend: [
        {
          label: "Confermato",
          color: "confermato"
        },
        {
          label: "Evaso/Concluso",
          color: "evaso"
        },
        {
          label: "Inoltrato/In attesa di risposta",
          color: "inoltrato-in-attesa"
        },
        {
          label: "Errore/Rifiutato/Annullato",
          color: "rifiutato-annullato"
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    showDetail(reservation) {
      this.selectedReservation = reservation
      this.$refs["modal-dettagliorec"].show()
    },
    addDay() {
      this.filters.date = moment(this.filters.date).add(1, "days")
      this.filters.date = moment(this.filters.date).format("YYYY-MM-DD")
      this.fetchData()
    },
    subtractDay() {
      this.filters.date = moment(this.filters.date).subtract(1, "days")
      this.filters.date = moment(this.filters.date).format("YYYY-MM-DD")
      this.fetchData()
    },
    fetchData() {
      this.loading = true
      var self = this
      reservationService
        .fetchUserCalendarDay(this.filters)
        .then(function (response) {
          // console.log("response fetch prenotazioni", response.data.data);
          self.prenotazioni = response.data.data
          self.prenotazioniBackend = self.prenotazioni
        })
        .catch(function (error) {
          self.errorAlert("non è stato possibile recuperare le prenotazioni")
          // console.log("error fetch prenotazioni", error);
        })
        .finally(function () {
          self.loading = false
        })
    },
    setPrev() {
      this.start.subtract(1, "weeks")
      this.end.subtract(1, "weeks")
      this.filters.from = moment(this.start).startOf("week").format("D/M/YY")
      this.filters.to = moment(this.end).endOf("week").format("D/M/YY")
      this.selectedDay = null
      this.selectedIndex = null
      this.fetchData()
    },
    setPost() {
      this.start.add(1, "weeks")
      this.end.add(1, "weeks")
      this.filters.from = moment(this.start).startOf("week").format("D/M/YY")
      this.filters.to = moment(this.end).endOf("week").format("D/M/YY")
      this.selectedDay = null
      this.selectedIndex = null
      this.fetchData()
    },
    setRef(ref, ind) {
      this.selectedDay = ref
      this.selectedIndex = ind
    },
    showReservDetail(res) {
      this.selectedReservation = res
      this.$bvModal.show("modal-dettagliorec")
    },
    handleStatusUpdate(data) {
      // console.log("data to submit", data);
      const item = this.selectedReservation
      var self = this
      if (!data.confirmed) {
        var form = data.message ? { message: data.message } : {}
        reservationService
          .userCancelOrReject(form, data.action, item.hashid)
          .then(function (re) {
            // self.$refs["modal-dettagliorec"].hide();
            window.scrollTo(0, 0)
            self.successAlert("Richiesta aggiornata con successo")
            self.fetchData()
          })
          .catch(function (error) {
            window.scrollTo(0, 0)
            self.errorAlert("non è stato possibile effettuare l'operazione")
          })
        this.$refs["modal-dettagliorec"].hide()
      } else {
        reservationService
          .userConfirmOrder(item.hashid)
          .then(function (re) {
            window.scrollTo(0, 0)
            self.successAlert("Richiesta aggiornata con successo")
            self.fetchData()
          })
          .catch(function (error) {
            self.loading = false
            window.scrollTo(0, 0)
            self.errorAlert("non è stato possibile effettuare l'operazione")
          })
        this.$refs["modal-dettagliorec"].hide()
      }
    },
    downloadPdf(res) {
      const hash = res.hashid
      const fName = res.codice_richiesta
      var self = this
      reservationService
        .scaricaPromemoria(hash)
        .then(function (response) {
          let blob = new Blob([response.data])
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download = fName + ".pdf"
          link.click()
        })
        .catch(function (error) {
          self.loading = false
          window.scrollTo(0, 0)
          self.errorAlert("non è stato possibile scaricare il promemoria")
        })
    },
    downloadCalendar(res) {
      const hash = res.hashid
      const fName = res.codice_richiesta
      var self = this
      reservationService
        .scaricaCalendar(hash)
        .then(function (response) {
          let blob = new Blob([response.data])
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download = fName + ".ics"
          link.click()
        })
        .catch(function (error) {
          self.loading = false
          window.scrollTo(0, 0)
          self.errorAlert("non è stato possibile scaricare il promemoria")
        })
    },
    handleNotesUpdate(data) {
      this.selectedReservation.user_notes = data.user_notes
    },
    handleUpdateRequest() {
      this.$bvModal.hide("modal-dettagliorec")
      this.successAlert("richiesta inviata con successo")
      this.fetchData()
    }
  },
  created() {
    this.filters.date = moment().format("YYYY-MM-DD")
    // this.filters.position_ref = this.servicerif;
    this.fetchData()
  },
  computed: {
    dateFormat() {
      if (this.innerWidth < 500)
        return {
          day: "numeric",
          month: "short",
          year: "numeric"
        }
      else if (this.innerWidth < 650)
        return {
          weekday: "long",
          day: "numeric",
          month: "long",
          year: "numeric"
        }
      else
        return {
          weekday: "long",
          day: "numeric",
          month: "long",
          year: "numeric"
        }
    }
  }
}
</script>
<style lang="scss">
#calendario-cittadino-mobile-datepicker__value_ {
  padding-left: 0 !important;
  padding-right: 0 !important;
  font-weight: 600;
  color: black;
  font-size: 20px;
}

button#calendario-cittadino-mobile-datepicker {
  padding: 0 !important;
}

div#calendario-cittadino-mobile-datepicker__outer_ {
  flex-basis: initial;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 8px #00000022;
  border-radius: 8px;
  border-radius: 0.15rem;
  opacity: 1;
  transition: all 200ms ease-in;
  border: 0;
}

.form-control#calendario-cittadino-mobile-datepicker__outer_ {
  min-width: 70vw;
}

@media (max-width: 400px) {
  .form-control#calendario-cittadino-mobile-datepicker__outer_ {
    min-width: 90vw;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

#timeElement {
  min-width: 26px !important;
  font-size: 10px;
  color: grey !important;
  font-weight: bolder;
  text-align: justify;
}

.tdTimeColumn {
  min-width: 26px !important;
  //   font-size: 10px;
  //   color: grey !important;
  //   font-weight: bolder;
  //   text-align: justify;
}

// tr {
//   border-bottom: 2px solid $line-grey !important;
//   // border-bottom: 2px solid $font-light-grey !important;
// }
// tr:last-of-type {
//   border-bottom: 0 solid $line-grey !important;
// }
// .thicker__border {
//   border-width: 3px !important;
// }

.thicker__border {
  border-width: 3px !important;
}

.rifiutato-annullato {
  color: rgba(253, 39, 75, 1) !important;
}

.bg-rifiutato-annullato {
  background-color: rgba(253, 39, 75, 1) !important;
}

.inoltrato-in-attesa {
  color: rgba(255, 151, 18, 1) !important;
  /* color: rgba(224, 242, 111, 1) !important; */
}

.bg-inoltrato-in-attesa {
  background-color: rgba(255, 151, 18, 1) !important;
  /* color: rgba(224, 242, 111, 1) !important; */
}

.confermato {
  color: rgba(32, 80, 114, 1) !important;
  /* color: rgba(205, 224, 201, 0.45) !important; */
}

.bg-confermato {
  background-color: rgba(32, 80, 114, 1) !important;
  /* color: rgba(205, 224, 201, 0.45) !important; */
}

.evaso {
  color: #01c0cb !important;
  /* color: rgba(73, 67, 255, 1) !important; */
}

.bg-evaso {
  background-color: #01c0cb !important;
}

.checkbox {
  font-size: 12px;
}

#calendario-cittadino-mobile-container {
  max-height: 60vh;
  overflow-y: auto;
}

.single-card {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 5px auto;
  border: 1px solid $primary;
  height: 3.5vh;
  border-radius: 3px;
  // width: 55px;
  width: 20vw;
  font-size: 0.7rem;
  background-color: whitesmoke;

  .paragraph-single-card {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    height: 100%;
    line-height: 3vh;
    letter-spacing: 0px;

    span.ora-inizio {
      // height: 100%;
      font-weight: bolder;
      margin: 0 1.5px;
      text-align: justify;
    }

    span.span-icon {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
